<template>
    <div class="system-container">
        <el-row>
            <el-button type="primary" size="small" @click="saveCompanyAppInfo">保存</el-button>
        </el-row>
        <el-row>
            <div class="oth-title">
                企业微信
            </div>
        </el-row>
        <el-row>
            <el-col :span="6">
                <label for="">corpId:</label>
                <el-input class="oth-input" v-model="companyInfo.wxCorpID"></el-input>
            </el-col>
            <el-col :span="6">
                <label for="">encodingAESKey:</label>
                <el-input class="oth-input" v-model="companyInfo.wxAppKey"></el-input>
            </el-col>
            <el-col :span="6">
                <label for="">appSecret:</label>
                <el-input class="oth-input" v-model="companyInfo.wxAppSecret"></el-input>
            </el-col>
        </el-row>
        <el-row>
            <div  class="oth-title">
                企业钉钉
            </div>
        </el-row>
        <el-row>
            <el-col :span="6">
                <label for="">corpId:</label>
                <el-input class="oth-input" v-model="companyInfo.ddCorpID"></el-input>
            </el-col>
            <el-col :span="6">
                <label for="">encodingAESKey:</label>
                <el-input class="oth-input" v-model="companyInfo.ddAppKey"></el-input>
            </el-col>
            <el-col :span="6">
                <label for="">appSecret:</label>
                <el-input class="oth-input" v-model="companyInfo.ddAppSecret"></el-input>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import api from "@/api/index.js";
export default {
    name: "otherApplication",
    data(){
        return {
            cusNo: "",
            companyInfo: {}
        }
    },
    mounted(){
        this.cusNo = sessionStorage.cusNo;
        this.initPage();
    },
    methods: {
        initPage() {
            api.companyInfo({
                cusNo: this.cusNo
            }).then(res => {
                if(res.code == 200) {
                    this.companyInfo = res.data.companyInfo;
                }
            })
        },
        saveCompanyAppInfo() {
            this.companyInfo.cusNo = this.cusNo;
            api.saveCompanyAppInfo(this.companyInfo).then(res => {
                if(res.code == 200) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                } else {
                    this.$message.error("保存失败");
                }
            });
        }
    }
}
</script>
<style>
.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.system-text {
    font-size: 13px;
}
.oth-title {
    border-left: 2px solid #0188e2;
    padding: 5px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
}
.oth-input {
    width: 60%;
    flex: 1;
    margin-left: 10px;
}
.oth-input.el-input > input {
    height: 33px;
}
.oth-link {
    font-size: 12px;
    margin-left: 20px;
}
</style>
